/* 
IonSelectionOption で複数行のオプションを表示するためのstyle
https://github.com/ionic-team/ionic-framework/issues/17269#issuecomment-470899761
https://stackoverflow.com/questions/56272023/how-to-add-new-line-in-ion-option-tag
*/

.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
  width: min-content;
  word-spacing: 9999px;
}
