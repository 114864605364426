.headerTitle {
  font-size: 14px;
}

.headerBackButton {
  --icon-font-size: 30px;
}

.headerRightButton {
  margin-right: 19px;
}
