.wrap {
  padding: 32px 20px 60px;
}

.projectInputBlock {
  padding: 24px 0 2px 0;
}

.projectInputBlock:first-child {
  padding-top: 0;
}

.inputInfoSection {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
}

.clientInputSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
}

.clientLinks {
  display: flex;
  font-size: 13px;
}

.clientLink {
  color: #1a1e29;
  text-decoration: none;
}

.clientLinkSeparator {
  padding: 0 4px;
}

.inputInfoSection:first-child {
  margin-top: 0;
}

.dateInputBlock {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dateLabel {
  font-weight: bold;
  width: 64px;
}

.dateText {
  flex-grow: 1;
  margin-left: 46px;
}

.dateCalendarIcon {
  color: #95949d;
}

.dateDescription {
  font-size: 12px;
}

.selectInput {
  background-color: #f4f4f6;
  border-radius: 10px;
  padding: 14px;
}

.selectInput::part(text) {
  white-space: normal;
  word-spacing: 9999px;
}

.imageUploadButton {
  width: 173px;
  height: 115.87px;
  --background: #fff;
  --background-hover: #fff;
  --background-activated: #fff;
  --background-focused: #fff;
  --border-color: #e9ebf2;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.fileInputLabel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadedImageContainer {
  width: 173px;
  height: 115.87px;
  border: 1px solid #e9ebf2;
  border-radius: 10px;
}

.uploadedImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.imageEditButtons {
  display: flex;
  justify-content: space-between;
}

.refreshImageIcon {
  border: 1px solid white;
  border-radius: 50%;
  font-size: 24px;
  padding: 4px;
  color: white;
  background: #2e2e2e36;
  position: relative;
  top: -60px;
  margin: 8px;
}

.deleteImageIcon {
  border: 1px solid white;
  border-radius: 50%;
  font-size: 24px;
  padding: 4px;
  color: white;
  background: #2e2e2e36;
  position: relative;
  top: -60px;
  margin: 8px;
}

.addImageIcon {
  font-size: 42px;
  color: #b4b9c7;
}

.contractPriceTax {
  color: #ff0d61;
}

.detailInputTextArea {
  background-color: #f4f4f6;
  border-radius: 10px;
  padding: 0 14px;
}

.contractFileSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.contractFileUpdateOrDeleteSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f6;
  border-radius: 10px;
  padding: 0 14px;
  margin-top: 12px;
}

.contractFileUploadButton {
  width: 100%;
  height: 58px;
  margin-top: 12px;
  --background: #fff;
  --background-hover: #fff;
  --background-activated: #fff;
  --background-focused: #fff;
  --border-color: #95959f;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.uploadedContractFileName {
  font-size: 12px;
  overflow: hidden;
}

.addContractFileIcon {
  position: relative;
  width: 26px;
  height: 26px;

  &::before {
    content: '';
    background-color: #52525d;
    position: absolute;
    width: 26px;
    height: 4px;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    margin-left: -13px;
  }

  &::after {
    content: '';
    background-color: #52525d;
    position: absolute;
    width: 26px;
    height: 4px;
    transform: rotate(90deg);
    top: 50%;
    left: 50%;
    transform-origin: center;
    margin-top: -2px;
    margin-left: -13px;
  }
}
.refreshContractFileLabel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.refreshContractFileIcon {
  border: 1px solid white;
  border-radius: 50%;
  font-size: 21px;
  padding: 8px;
  color: #1a1e29;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
  margin: 12px;
}

.deleteContractFileIcon {
  border: 1px solid white;
  border-radius: 50%;
  font-size: 21px;
  padding: 8px;
  color: #1a1e29;
  background: #fff;
  box-shadow: 0 2px 6px 0 rgb(0, 0, 0, 0.25);
  margin: 12px;
}

.contractFileExtensionText {
  font-weight: bold;
  font-size: 18px;
}

.paymentDueDateDescription {
  font-size: 12px;
  margin-top: 12px;
}

.paymentDueSelectSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4f4f6;
  border-radius: 10px;
  padding: 0 14px;
  margin-top: 12px;
}

.paymentDueDateText {
  font-size: 14px;
}
