.pdfFile {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  text-decoration: none;
}

.otherFile {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  text-decoration: none;
}

.pdfFile {
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 10px 15px;
  font-size: 14px;
  text-decoration: none;
}

.imageFile {
  display: block;
  margin: 0;
  font-size: 14px;
  max-width: 180px;
}

.icon {
  flex-shrink: 0;
  width: 19px;
  height: 22px;
  margin-right: 8px;
  fill: #1a1e29;
}

.fileName {
  color: #3880ff;
  max-width: 350px;
  word-break: break-all;
}
