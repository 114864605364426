.button {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 0;
  --padding-end: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 32px;
  padding: 0;
  line-height: 1;
  background-color: transparent;
  margin-left: 10px;
}

.icon {
  color: #1a1e29;
}

:global(.sc-ion-buttons-ios-s ion-icon[slot='end']) {
  --icon-margin-end: -5px;
  --icon-margin-start: -4px;
  --icon-font-size: 31px;
  flex-shrink: 0;
  margin-inline-start: var(--icon-margin-start);
  margin-inline-end: var(--icon-margin-end);
  padding-inline-start: var(--icon-padding-start);
  padding-inline-end: var(--icon-padding-end);
  margin-top: -1px;
  font-size: var(--icon-font-size);
}
