.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  width: 100%;
}

.explanation {
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
}

.confirmButton {
  --background: #fff;
  --background-activated: #eaeaed;
  --color: #000;
  width: 133px;
  margin: 24px auto;
  font-size: 13px;
  font-weight: bold;
}

.datePicker {
  --background: #eaeaed;
}

.closeButton {
  margin-right: auto;
  margin-left: 24px;
  margin-top: 24px;
  --background: #fff;
  --background-activated: #eaeaed;
  --color: #b6b9c6;
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  width: 40px;
  height: 40px;
}

.closeIcon {
  font-size: 32px;
}
