.wrap {
  padding: 32px 20px 60px;
}

.logo {
  margin: 86px auto 56px;
  width: 81px;
  height: 111px;
}

.img {
  width: 70px;
  height: 70px;
}

.note {
  text-align: center;
  font-size: 18px;
}

.links {
  margin: 78px 0 15px;
}

.link {
  --min-height: 36px;
  --padding-start: 0;
  --inner-padding-end: 0;
  font-size: 14px;
  font-weight: bold;
}

.arrow {
  width: 7px;
  margin-inline: 0;
}
