.welcome {
  position: relative;
  height: 100%;
  box-sizing: border-box;
}

.eyecatch {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100% - 120px);
}

.logo {
  margin: 0 auto;
  width: 121px;
  height: 162px;
}

.buttons {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.button {
  --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
  --color: #1a1e29;
  display: flex;
  justify-content: center;
  height: 60px;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #e9ebf2;

  &:first-child {
    border-top: 1px solid #e9ebf2;
  }
}
