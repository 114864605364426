.wrap {
  padding: 32px 20px 60px;

  &.smartPhone {
    // iphone絵文字入力もあるのため、バーチャルキーボードと被らないようにより高く隙間を入れる
    // 画面下部のIonic入力要素をタップしても遅延が発生しないようにするために、高く隙間を入れる
    padding-bottom: 480px;
  }
}

.profileInputBlock {
  padding: 24px 0 2px 0;
}

.profileInputBlock:first-child {
  padding-top: 0;
}

.inputInfoSection {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 10px;

  &Bust {
    margin-top: 6px;
  }
}

.required {
  color: #ff0079;
}

.inputInfoSection:first-child {
  margin-top: 0;
}

.imageInputSection {
  text-align: center;
  margin-top: 80px;
  margin-bottom: 80px;
}

.accountNameInputSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.atMark {
  margin-right: 10px;
  font-weight: bold;
}

.inputSectionWithUnit {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.numberInput {
  max-width: 189px;
}

.unit {
  margin-left: 6px;
  font-weight: bold;
}

.selectInput {
  background-color: #f4f4f6;
  border-radius: 10px;
  padding: 14px;
  max-width: 189px;
  width: 100%;
}

.selectInput::part(text) {
  white-space: normal;
  word-spacing: 9999px;
}

.imageUploadButton {
  position: relative;
  width: 137px;
  height: 137px;
  --background: #fff;
  --background-hover: #fff;
  --background-activated: #fff;
  --background-focused: #fff;
  --border-color: #e9ebf2;
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
}

.imageUploadImage {
  position: absolute;
  top: -74px;
  width: 137px;
  height: 211px;
}

.fileInputLabel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.uploadedImageContainer {
  width: 137px;
  height: 137px;
  // border: 1px solid #e9ebf2;
  // border-radius: 10px;
  margin: 0 auto;
}

.uploadedImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 68px;
}

.imageEditButtons {
  display: flex;
  justify-content: space-between;
}

.refreshImageIcon {
  border: 1px solid white;
  border-radius: 50%;
  font-size: 24px;
  padding: 4px;
  color: white;
  background: #2e2e2e36;
  position: relative;
  top: -60px;
  margin: 8px;
}

.deleteImageIcon {
  border: 1px solid white;
  border-radius: 50%;
  font-size: 24px;
  padding: 4px;
  color: white;
  background: #2e2e2e36;
  position: relative;
  top: -60px;
  margin: 8px;
}

.addImageIcon {
  font-size: 42px;
  color: #b4b9c7;
}

.detailInputTextArea {
  background-color: #f4f4f6;
  border-radius: 10px;
  padding: 0 14px;
}
