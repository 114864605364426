.headerTitle {
  position: absolute;
  height: 48px;
  width: 100%;
  top: 0;
  left: 0;
  padding-top: var(--padding-top);
  padding-bottom: var(--padding-bottom);
  padding-inline: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.headerTitleSpan {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-weight: bold;
  overflow-wrap: anywhere;
  line-break: anywhere;
  text-align: center;
  font-size: 14px;
}

.headerCloseButton {
  --icon-font-size: 30px;
}
