.projectMessageFabButton {
  margin-bottom: 98px;
  height: 42px;
  width: 42px;
  margin-right: 7px;
  --background: #00a5c3;
  --color: black;
  --background-activated: #0000001f;
}

.projectMessageFab {
  width: 26px;
  margin: auto;
}

.addFabIcon {
  position: absolute;
  width: 12px;
  z-index: 1;
  color: red;
  right: 5px;
  top: -2px;
}
