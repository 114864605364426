.projectFab {
  bottom: 136px;
}

.projectFabButton {
  height: 60px;
  width: 60px;
  margin-right: 7px;
  --background: white;
  --color: black;
  --background-activated: #0000001f;
}

.projectFabButtonIcon {
  font-size: 40px;
  color: #52525d;
}
