.pageMargin {
  margin-top: 27px;
  margin-left: 20px;
  height: calc(100% - 48px);
}

.itemMargin {
  margin-bottom: 34px;
}

.colorPickerTitle {
  font-size: 14px;
  color: #1a1e29;
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  margin-bottom: 16px;
}

.colorPickerBox {
  width: 185px;
  height: 50px;
  background-color: #f5f6f8;
  color: #1a1e29;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
}

.arrangePickerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resetButton {
  margin-right: 24px;
  font-size: 14px;
  color: #008ed6;
}
