.wrap {
  padding: 32px 20px 35px;
}

.note {
  margin: 0 0 9px;
  font-size: 14px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.button {
  --box-shadow: none;
  --border-radius: 30px;
  width: 157px;
  height: 42px;
  margin-inline: 0;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  border-radius: var(--border-radius);
  box-shadow: 0px 3px 12px #1a1e291a;
  --background: #008ed6 0% 0% no-repeat padding-box;
}

.searchResultTitle {
  font-size: 18px;
  text-align: center;
  margin-bottom: 13px;
  padding-left: 0;
}

.searchResultItem {
  font-size: 14px;
  --padding-start: 0;
  --inner-padding-top: 10px;
  --inner-padding-bottom: 10px;
}

.user {
  padding-left: 20px;
}

.profileBtn {
  font-size: 14px;
  font-weight: bold;
  color: #00a5c3;
}

.withdrawBtn {
  font-size: 14px;
  font-weight: bold;
  color: #ff0062;
}

.deleteBtn {
  font-size: 14px;
  font-weight: bold;
  color: #ff0062;
}
