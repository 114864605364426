.title {
  padding: 0 40px; // 左側の戻るボタンを被らないようにpaddingを設ける
}

.question {
  margin-left: auto !important;
  margin-right: 20px !important;
  font-size: 14px;
  font-weight: bold;
  --color: #00a5c3;
}
