.wrap {
  padding: 32px 20px 60px;
}

.note {
  margin: 0;
  font-size: 14px;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.button {
  --box-shadow: none;
  --border-radius: 30px;
  width: 120px;
  height: 42px;
  margin-inline: 0;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  border-radius: var(--border-radius);
  box-shadow: 0 3px 12px 0 rgba(#1a1e29, 0.1);
  --background: #00a5c3 0% 0% no-repeat padding-box;
}
