.wrap {
  margin: 16px 18px;
}

.projectInfoArea {
  background: #f5f6f8;
  border-radius: 10px;
  padding: 16px;
}

.salesDate {
  font-size: 11px;
}

.projectName {
  font-size: 13px;
  font-weight: bold;
}

.clientName {
  font-size: 11px;
  margin-top: 6px;
}

.contractPrice {
  font-size: 11px;
  margin-top: 16px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.chatButton {
  --background: #fff;
  --background-activated: #e9ebf2;
  --border-color: #e9ebf2;
  --border-style: solid;
  --border-width: 1px;
  --color: #000000;
  --color-activated: #000000;
  font-size: 13px;
  font-weight: bold;
  height: 44px;
  width: 133px;
}

.openPaymentConfirmModalButton {
  --background: #f9e4ee;
  --color: #ff0079;
  --color-activated: #ff0079;
  --background-activated: #f5cfe1;
  font-size: 13px;
  font-weight: bold;
  height: 44px;
  width: 87px;
}
