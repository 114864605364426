.wrap {
  padding: 32px 20px 60px;
}

.focused {
  padding-bottom: 240px;
}

.clientInputBlock {
  padding: 24px 0 2px 0;
}

.clientInputBlock:first-child {
  padding-top: 0;
}

.inputInfoSection {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  margin-top: 32px;
}

.inputInfoSection:first-child {
  margin-top: 0;
}

.chatUrlButton {
  margin-top: 35px;
  color: #1a1e29;
  font-size: 16px;
  --background-activated: #fff;
  --border-color: #b4b9c7;
  --border-radius: 10px;
  --transition: none;
}
