.tabBar {
  height: 84px;
}

.tab {
  position: relative;
}

.icon {
  margin: 0;
  height: 25px;
  color: #c8ccd5;
  font-size: 23px;
}

.active {
  color: black;
}

.ionLabel {
  margin-top: 14px;
  font-size: 11px;
  color: #1a1e29;
}
