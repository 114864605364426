.wrap {
  padding: 32px 20px 60px;
}

.terms {
  --min-height: 26px;
  --padding-start: 0;
  --inner-padding-end: 0;
  margin: 20px 0;
  font-size: 14px;
  font-weight: bold;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
}

.checkbox {
  --background: #f5f6f8;
  --border-radius: 5px;
  --border-width: 0;
  --size: 21px;
  margin-right: 8px;
  --background-checked: #00a5c3;
}

.label {
  display: inline-block;
  color: #1a1e29;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 10px 0;
}

.button {
  --box-shadow: none;
  --border-radius: 30px;
  width: 120px;
  height: 42px;
  margin-inline: 0;
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  border-radius: var(--border-radius);
  box-shadow: 0 3px 12px 0 rgba(#1a1e29, 0.1);
  --background: #00a5c3 0% 0% no-repeat padding-box;
}

.row {
  position: relative;
}

.eye {
  position: absolute;
  top: 50%;
  right: 0;
  margin: -10px 15px 0 0;
  font-size: 20px;
  z-index: 2;
}

.links {
  margin: 40px 0 15px;
}

.link {
  --min-height: 36px;
  --padding-start: 0;
  --inner-padding-end: 0;
  font-size: 14px;
  font-weight: bold;
}

.arrow {
  width: 7px;
  margin-inline: 0;
}
