.wrapGraphArea {
  margin: 0 18px 18px;

  canvas {
    margin: 0 auto;
  }
}

.wrapProjectsArea {
  overflow: auto;
}

.graph {
  height: 200px;
}

.chooseYearButtonArea {
  padding: 21px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #00a5c30f;
  border-radius: 10px;
}

.currentYearSalesText {
  font-size: 18px;
  flex-grow: 1;
  font-weight: bold;
  text-align: center;
}

.chooseYearButton {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0;
  --padding-bottom: 0;
  color: #00a5c3;
  font-size: 24px;
  height: 24px;
}

.projectListTitle {
  font-size: 18px;
  font-weight: bold;
  margin-top: 36px;
  margin-left: 18px;
}

.projectItemList {
  padding-left: 0;

  > li + li {
    border-top: 1px solid #e9ebf2;
  }

  > li {
    list-style: none;
  }
}

.downloadButton {
  --background: #00a5c3;
  --color: #ffffff;
  font-weight: bold;
  position: fixed;
  right: 14px;
  bottom: 106px;
}

.noSaleData {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 18px;
  }
}
