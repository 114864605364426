.detailPageLayout {
  margin: 29px 27px 29px 24px;
}

.projectImage {
  padding-top: 14px;
  padding-bottom: 29px;
  border-radius: 10px;
}

.projectDefaultImage {
  width: 100%;
}

.columnFlex {
  display: flex;
  justify-content: space-between;
  margin-bottom: 49px;
}

.columnTitle {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 6px;
}

.priceUnit {
  font-size: 12px;
  padding-top: 12px;
  font-weight: bold;
}

.priceText {
  font-size: 18px;
  padding-top: 12px;
  font-weight: bold;
}

.description {
  padding-top: 17px;
  font-size: 14px;
  white-space: pre-line;
}

.contractFileButton {
  width: 100%;
  height: 58px;
  --border-color: #95959f;
  margin-top: 36px;
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: bold;
  color: #1a1e29;
}

.littleText {
  font-size: 12px;
  padding-top: 12px;
}

.projectStatusContracted {
  font-size: 11px;
  width: 63px;
  height: 21px;
  background-color: #f9e4ee;
  color: #e697bc;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  padding-top: 2px;
}

.projectStatusDepositCompleted {
  font-size: 11px;
  width: 75px;
  height: 21px;
  background-color: #00a5c3;
  color: white;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  padding-top: 2px;
}

.projectStatusNegotiation {
  font-size: 11px;
  width: 63px;
  height: 21px;
  background-color: #b4b9c7;
  color: white;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  padding-top: 2px;
}

.projectTitle {
  font-size: 16px;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 8px;
}

.projectCompany {
  font-size: 12px;
}
