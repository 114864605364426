.modalCardWrap {
  width: 280px;
  height: 198px;
  background-color: #ffffff;
  border-radius: 20px;
  padding: 22px 25px 26px 22px;
}

.modalCard {
  display: flex;
  align-items: center;
}

.modalTypography {
  margin-left: 15px;
  font-size: 14px;
  font-weight: bold;
}

.modalDescription {
  font-size: 12px;
  margin-top: 22px;
  padding-left: 20px;
}
