@use 'sass:math';

.ionButton {
  --background: white;
  --color: black;
  font-size: 12px;
  width: 35px;
  padding-bottom: 10px;
}

.deleteButton {
  width: 61px;
  background-color: #ff0079;
}

.projectCard {
  min-height: 111px;
  display: flex;
  justify-content: space-between;
  width: 100vw;
  padding-top: 23px;
  padding-right: 13px;
  padding-left: 4px;
}

.projectLabel {
  height: 111px;
  width: 100%;
}

.projectStatusContracted {
  font-size: 11px;
  width: 63px;
  height: 21px;
  background-color: #f9e4ee;
  color: #e697bc;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  padding-top: 2px;
}

.projectStatusDepositCompleted {
  font-size: 11px;
  width: 75px;
  height: 21px;
  background-color: #00a5c3;
  color: white;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  padding-top: 2px;
}

.projectStatusNegotiation {
  font-size: 11px;
  width: 63px;
  height: 21px;
  background-color: #b4b9c7;
  color: white;
  text-align: center;
  border-radius: 6px;
  font-weight: bold;
  padding-top: 2px;
}

.projectTitle {
  font-size: 16px;
  font-weight: bold;
  padding-top: 6px;
  padding-bottom: 8px;
}

.projectCompany {
  font-size: 12px;
  padding-bottom: 11px;
}

.orderList {
  font-size: 12px;
  height: 32px;
  margin-top: 10px;
  padding-right: 24px;
  width: 100%;
  text-align: end;
}

.orderButton {
  background-color: white;
  font-size: 12px;
}

.plusButton {
  width: 61px;
  background-color: #ff0079;
}

.projectImage {
  height: 65px;
  border-radius: 10px;
}

.projectStatusWrap {
  display: flex;
  align-items: center;
}

.projectLatestNotification {
  margin-left: 5px;
  color: #fd2179;
}
