.wrap {
  background: #f5f6f8;
  border-radius: 10px;
  padding: 16px;
  margin: 16px 18px;
}

.topFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.salesDate {
  font-size: 11px;
}

.paymentStatus {
  background: #00a5c3;
  border-radius: 6px;
  padding: 5px 16px;
  color: #ffffff;
  font-weight: bold;
  font-size: 11px;
}

.projectName {
  font-size: 13px;
  margin-top: 16px;
  font-weight: bold;
}

.clientName {
  font-size: 11px;
  margin-top: 6px;
}

.bottomFlex {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contractPrice {
  font-size: 11px;
  line-height: 11px;
}

.returnToUnpaid {
  font-size: 12px;
  color: #b4b9c7;
  background: transparent;
}
