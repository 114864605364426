.projectItemList {
  padding-left: 0;

  > li + li {
    border-top: 1px solid #e9ebf2;
  }

  > li {
    list-style: none;
  }
}
