.links {
  padding: 0 0 60px;
}

.link {
  --min-height: 67px;
  --padding-start: 0;
  --inner-padding-end: 0;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #e9ebf2;
  padding: 0 20px;
}

.blank {
  --min-height: 80px;
  --padding-start: 0;
  --inner-padding-end: 0;
  font-size: 14px;
  font-weight: bold;
  border-bottom: 1px solid #e9ebf2;
}

.profileBtn {
  margin: auto;
}

.browser {
  font-size: 24px;
}

.arrow {
  width: 7px;
  margin-inline: 0;
}
