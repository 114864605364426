.wrap {
  padding: 32px 20px 60px;
}

.plan {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #1a1e29;
  text-align: left;
}

.buttons {
  display: flex;
  justify-content: center;
  margin: 57px 0 10px;
}

.button {
  --box-shadow: none;
  --border-radius: 30px;
  width: 120px;
  height: 42px;
  margin-inline: 0;
  font-size: 14px;
  font-weight: bold;
  border-radius: var(--border-radius);
  box-shadow: 0px 3px 12px #1a1e291a;
  --background: #00a5c3 0% 0% no-repeat padding-box;
}
