.wrap {
  margin: 28px 20px 98px;
}

.photoRow {
  display: flex;

  & + & {
    margin-top: 14px;
  }
}

.photoCard {
  width: var(--photo-img-size);
  height: var(--photo-img-size);
  background: #f5f6f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  position: relative;

  & + & {
    margin-left: 14px;
  }
}

.changeCircle {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 8px;
  right: 7px;
  background: #ffffff1a 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff80;
}

.changePhotoIcon {
  width: 19px;
  height: 16px;
}

.addPhotoIcon {
  padding: 67px;
}

.uploadCircle {
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 7px;
  left: 8px;
  background: #fff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
}

.uploadPhotoIcon {
  width: 15px;
  height: 15px;
}

.trashCircle {
  width: 34px;
  height: 34px;
  position: absolute;
  bottom: 7px;
  right: 7px;
  background: #ffffff1a 0% 0% no-repeat padding-box;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #ffffff80;
}

.trashPhotoIcon {
  width: 16px;
  height: 16px;
}

.photo {
  width: 100%;
  height: 100%;
}

.photo::part(image) {
  border-radius: 10px;
}
