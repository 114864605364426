.chatBackGround {
  --background: #ecf7f8;
}

.chatContent {
  margin: 22px 13px 30px 22px;
}

.chatContentModelHeader {
  display: flex;
  justify-content: space-between;
  font-size: 11px;
}

.chatContentClientHeader {
  display: flex;
  justify-content: flex-end;
  font-size: 11px;
}

.chatContentClientHeader .chatContentHeaderDatetime,
.chatContentModelHeader .chatContentHeaderDatetime,
.chatContentModelHeader .chatContentHeaderModelName {
  color: #52525d;
}

.chatContentHeaderModelName {
  font-weight: bold;
}

.chatModelCard {
  max-width: 301px;
  display: inline-block;
  border-radius: 0 20px 20px 20px;
  color: #1a1e29;
  background-color: #d3e6f2;
  box-shadow: none;
  margin: 9px 0 0 0;
}

.chatModelCardImg {
  max-width: 301px;
  display: inline-block;
  border-radius: 0 20px 20px 20px;
  color: #1a1e29;
  background-color: transparent;
  box-shadow: none;
  margin: 9px 0 0 0;
}

.chatClientCard {
  max-width: 301px;
  display: inline-block;
  border-radius: 20px 0 20px 20px;
  color: #1a1e29;
  box-shadow: none;
  margin: 9px 0 0 39px;
}

.chatClientCardImg {
  max-width: 301px;
  display: inline-block;
  border-radius: 20px 0 20px 20px;
  color: #1a1e29;
  background-color: transparent;
  box-shadow: none;
  margin: 9px 0 0 39px;
}

.chatCardWrap {
  display: flex;
  justify-content: flex-end;
}

p {
  margin: 0;
}

.chatCardContent {
  white-space: pre-wrap;
}

.chatInfiniteScrollContent {
  min-height: 1px; // 無限スクロールが効くための最小の高さを設定
}
