.footer {
  height: 58px;

  &Focused {
    height: 116px;
  }
}

.footerToolbar {
  height: 100% !important;
}

.ionInputFooterText {
  overflow: hidden;
  // ionicデフォルトのスタイルに優先度が負けてしまうためimportantが必要
  width: calc(100% - 15px) !important;
  height: 36px;
  margin: 11px 18px 11px 6px !important;

  &Focused {
    height: 96px;
  }

  textarea {
    padding: 10px !important;
  }
}

.footerInputFile input[type='file'] {
  display: none;
}

.footerSendDisabledBtn {
  font-size: 14px;
  --color: #52525d;
  margin-left: 4px !important;
}

.footerSendActiveBtn {
  font-size: 14px;
  --color: #00a5c3;
  margin-left: 4px !important;
}

.footerFileButton {
  margin: auto 13px auto 0;
  height: 100%;
}

.footerFileDisabledImg {
  width: 22px;
  fill: #52525d;
}

.footerFileActiveImg {
  width: 22px;
  fill: #00a5c3;
}
